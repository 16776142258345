.flex {
  display: flex;
}

.navbar {
  margin-bottom: 1em;
}

.table-btn-filter > .dropdown-toggle.btn.btn-link {
  padding: 0;
  font: inherit;
  color: inherit;
}

.header-btn-grp {
  width: 100%;
  justify-content: space-between;
}

.actions-btn-grp {
  align-self: center;
}

.filter-btn-grp {
  align-self: center;
  margin-left: 8px;
}

.table-withbutton tr td {
  vertical-align: middle;
}

.middle {
  text-align: center;
}

.sortable-item {
  padding: 2.5em 1%;
  margin: 1%;
  width: 31%;
  text-align: center;
  box-shadow: 3px 4px 8px 0px grey;
  border-radius: 0.2rem;
}

.sortable-container {
  margin: 2em auto 2em auto;
  justify-content: flex-start;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.loginbutton:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.logininput:hover {
  background: rgba(255, 255, 255, 0.9) !important;
}

.logininput:focus {
  background: rgba(255, 255, 255, 0.9) !important;
  outline: none;
}
